<template>
  <main
    class="container mx-auto flex flex-col items-center mt-6 px-4 max-w-2xl"
  >
    <h1 class="text-2xl font-bold mb-4">ログイン</h1>
    <p class="text-xs text-gray-500 mb-6">
      ログインを以って
      <a
        href="https://drive.google.com/file/d/1HfNFqVDUF-ntYGa-SIVHRHg8VD-wx9Vg/view"
        target="_blank"
        rel="noopener noreferrer"
        class="text-primary-500 transition-colors duration-300 hover:text-primary-600"
      >
        利用規約
      </a>
      に同意します
    </p>
    <router-link
      to="/auth/reader"
      class="flex flex-col justify-center items-center p-4 bg-white rounded-md mx-auto text-gray-500"
    >
      <img
        src="@/assets/qr_scan_icon.png"
        alt="QRコードスキャンアイコン"
        class="w-40 h-40"
      />
    </router-link>
    <div class="text-gray-500 py-4">または</div>
    <m-text-field
      label="生徒ログインID"
      name="id"
      type="text"
      placeholder="000001-mingaku"
      :value="loginId"
      :invalid="!validLoginId"
      invalid-message="生徒ログインIDを正しく入力してください"
      :entered="enteredLoginId"
      class="mb-4"
      @input="loginId = $event"
      @enter="enteredLoginId = true"
    />
    <m-text-field
      label="パスワード"
      name="password"
      type="password"
      placeholder="半角英数字記号8文字以上"
      :value="password"
      :invalid="!validPassword"
      invalid-message="パスワードは半角英数字記号8文字以上で入力してください"
      :entered="enteredPassword"
      class="mb-4"
      @input="password = $event"
      @enter="enteredPassword = true"
    />
    <m-button :invalid="!validData && allEntered" class="mb-8" @click="signIn">
      ログイン
    </m-button>
  </main>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import liff from "@line/liff";
import { signInWithIdAndPassword } from "@/api/auth";
import MCommentPanel from "@/components/MCommentPanel.vue";
import MButton from "@/components/form/MButton.vue";
import MTextField from "@/components/form/MTextField.vue";
import store from "@/store";
import { saveErrorLog } from "@/api/error";

@Options({
  components: {
    MCommentPanel,
    MButton,
    MTextField
  }
})
export default class Login extends Vue {
  loginId = "";
  enteredLoginId = false;
  password = "";
  enteredPassword = false;

  get validLoginId(): boolean {
    return (
      /^[\x21-\x7E]+$/.test(this.loginId) && String(this.loginId).length >= 5
    );
  }

  get validPassword(): boolean {
    return /^[a-zA-Z0-9!-/:-@¥[-`{-~]{8,64}$/.test(this.password);
  }

  get validData() {
    return this.validLoginId && this.validPassword;
  }

  get allEntered(): boolean {
    return this.enteredLoginId && this.enteredPassword;
  }

  allEnter() {
    this.enteredLoginId = true;
    this.enteredPassword = true;
  }

  async signIn() {
    this.allEnter();

    if (!this.validData) {
      return;
    }

    store.commit("SET_LOADING", true);
    store.commit("SET_LOAD_TEXT", "ログイン処理中...");

    let token: string | undefined;
    if (store.state.isInLiff) {
      if (!liff.isLoggedIn()) {
        store.commit("SET_LOADING", false);
        store.commit("SET_LOAD_TEXT", "");
        alert("LINEのログインが完了していません。アプリを開き直してください。");
        return;
      }
      const accessToken = liff.getAccessToken();
      token = accessToken ? accessToken : undefined;
    }

    try {
      await signInWithIdAndPassword(this.loginId, this.password, token);
    } catch (e) {
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      if (e.code === "not-found") {
        alert(
          "ログインIDに一致するユーザーが見つかりませんでした。\n入力したログインIDをご確認の上で再度実行してみてください。"
        );
      } else if (e.code === "permission-denied") {
        alert(
          "パスワードが間違っています。\n入力したパスワードをご確認の上で再度実行してみてください。"
        );
      } else {
        alert(`ログインに失敗しました\n\n${e}`);
        await saveErrorLog(
          store.state.student,
          e.code,
          e.message,
          "Failed to sign in"
        );
      }
      return;
    }

    store.commit("SET_LOADING", false);
    store.commit("SET_LOAD_TEXT", "");
    const path = store.state.redirect;
    this.$router.replace(path);
  }

  created() {
    store.commit("SET_LOADING", false);
    store.commit("SET_LOAD_TEXT", "");
  }
}
</script>
